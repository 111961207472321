import { DISCOUNT } from "@/constants";
import { Box, type BoxProps, Text } from "@chakra-ui/react";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

function calculateDiscount({
  apartmentSlug,
  apartmentType,
  citySlug,
}: {
  apartmentSlug: string;
  apartmentType?: string | null;
  citySlug: string;
}): {
  discount: number;
  footnote?: string;
} | null {
  const cityDiscount = DISCOUNT[citySlug];

  if (!cityDiscount) {
    return null;
  }

  if (cityDiscount.apartmentSlugs && cityDiscount.apartmentSlugs[apartmentSlug.toLowerCase()]) {
    return cityDiscount.apartmentSlugs[apartmentSlug.toLowerCase()];
  }

  if (cityDiscount.apartmentTypes && apartmentType && cityDiscount.apartmentTypes[apartmentType.toLowerCase()]) {
    return cityDiscount.apartmentTypes[apartmentType.toLowerCase()];
  }

  return {
    discount: cityDiscount.discount,
    footnote: cityDiscount.footnote,
  };
}

interface PriceProps extends BoxProps {
  apartmentSlug: string;
  apartmentType?: string | null;
  startingPrice: number;
  currency?: string;
  citySlug?: string;
  i18nKey?: string;
  includeFootnote?: boolean;
  includeDiscount?: boolean;
  children?: React.ReactNode;
}

export function Price({
  apartmentSlug,
  apartmentType,
  citySlug,
  currency,
  startingPrice,
  i18nKey,
  includeFootnote,
  includeDiscount = true,
  children,
  ...rest
}: PriceProps) {
  const { t } = useTranslation("common");
  const { locale, query } = useRouter();
  const slug = citySlug ?? (query.uid as string);

  const { discount, footnote } = calculateDiscount({ apartmentSlug, apartmentType, citySlug: slug }) ?? {};

  const price = new Intl.NumberFormat(locale, {
    style: "currency",
    compactDisplay: "short",
    maximumFractionDigits: 0,
    currency,
  }).format(startingPrice);

  const discountedPrice =
    typeof discount === "number"
      ? new Intl.NumberFormat(locale, {
          style: "currency",
          compactDisplay: "short",
          maximumFractionDigits: 0,
          currency,
        }).format(startingPrice * (1 - discount / 100))
      : null;

  return discountedPrice && discountedPrice !== price && includeDiscount ? (
    <>
      {children ? (
        <Box {...rest}>
          {children}
          <Box>
            <Trans
              i18nKey={i18nKey ? `${i18nKey}-discounted` : "common:discounted-price"}
              components={{
                price: <Text as="span" fontWeight="semibold" color="gray.900" textDecoration="line-through" />,
                discountedPrice: <Text as="span" fontWeight="semibold" color="almond" />,
              }}
              values={{
                price,
                discountedPrice,
              }}
            />
          </Box>
        </Box>
      ) : (
        <Trans
          i18nKey={i18nKey ? `${i18nKey}-discounted` : "common:discounted-price"}
          components={{
            price: <Text as="span" fontWeight="semibold" color="gray.900" textDecoration="line-through" />,
            discountedPrice: <Text as="span" fontWeight="semibold" color="almond" />,
          }}
          values={{
            price,
            discountedPrice,
          }}
        />
      )}
      {includeFootnote && footnote ? (
        <Text as="span" display="block" color="gray.600" fontSize="xs" mb={2}>
          {t(footnote)}
        </Text>
      ) : null}
    </>
  ) : children ? (
    <Box {...rest}>
      {children}
      <Box>
        <Trans
          i18nKey={i18nKey ? i18nKey : "common:price"}
          components={{ price: <Text as="span" fontWeight="semibold" color="gray.900" /> }}
          values={{
            price,
          }}
        />
      </Box>
    </Box>
  ) : (
    <Trans
      i18nKey={i18nKey ? i18nKey : "common:price"}
      components={{ price: <Text as="span" fontWeight="semibold" color="gray.900" /> }}
      values={{
        price,
      }}
    />
  );
}
